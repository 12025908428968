<!-- @format -->
<script lang="ts">
  import { getStoresContext } from "~/stores";
  import { getMovieDuration } from "./utils";
  import type { Biorex } from "~/mall-of-tripla/biorex/type";

  export let request_store_key;
  export let biorex: Biorex;
  export let aspectRatio = 0

  const { T } = getStoresContext(request_store_key);
  $: t = $T;
  $: duration = getMovieDuration(biorex.start, biorex.end);
</script>

<div class="biorex-card">
  <a
    href='{biorex.event_url}/?cinema_id={biorex.theatre_id}'
    target="_blank"
    rel="noreferrer"
    class="biorex-card-link">
    <div class="biorex-card-image-wrapper" style="padding-top: {aspectRatio * 100}%;">
      {#if biorex.image}
        <img
          loading="lazy"
          alt={biorex.title}
          data-src={biorex.image}
          class="lazy-loading-src" />
      {/if}
    </div>
    <div class="biorex-card-content-wrapper">
      <div class="title" title={biorex.title}>{@html biorex.title}</div>
      <div>{duration}</div>
      <div class="genres" title={biorex.genres}>{biorex.genres}</div>
    </div>
    <div class="purple-outline-button">
      <span>{t`buy-movie-ticket`}</span>
    </div>
  </a>
</div>

<style lang="scss">
  .title,
  .genres {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin: 0;
  }

  .biorex-card {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0 8px;
    min-height: 470px;
  }

  .biorex-card-link {
    display: grid;
    grid-template-rows: min-content auto min-content;
    position: relative;
    width: 100%;
    height: 100%;
    color: inherit;
    text-decoration: none;

    &:hover {
      .purple-outline-button {
        background: var(--color-accent-primary);
        color: #fff;
      }
    }
  }

  .biorex-card-image-wrapper {
    position: relative;
    background-color: #fff;
    width: 100%;
  }

  .biorex-card-image-wrapper img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .biorex-card-content-wrapper {
    position: relative;
    z-index: 2;
    padding: 16px 0;
    font-size: 16px;
    text-align: center;
    background: #fff;
    display: flex;
    flex-direction: column;
    gap: 8px;
    min-height: 120px;
  }
  
  .purple-outline-button {
    position: relative;
    z-index: 2;
    display: block;
    margin: 0;
    padding: 8px;
    width: 100%;
    text-align: center;
    font-size: 14px;
    background: #fff;
    border: 2px solid var(--color-accent-primary);
    color: var(--color-accent-primary);
    transition: all 0.2s ease-in-out;

    @media screen and (min-width: 768px) {
      font-size: 16px;
      padding: 12px;
    }
  }
</style>
