<!-- @format -->
<script lang="ts">
  import type { Link } from "~/components/field-utils";
  export let linkTextbox: Link = {
    text: "",
    href: "",
    outbound: false,
  };
  export let backgroundColor = "#6950a1"
  export let textColor = "#FFF"
  export let aspectRatio = 0

  const { text, href, outbound } = linkTextbox;

  $: target = outbound == true ? "_blank" : "";
</script>

{#if text && href}
  <div class="biorex-card">
    <a class="biorex-card-image-wrapper" {href} {target} style="padding-top: {aspectRatio * 100}%; background-color: {backgroundColor}; color: {textColor}">
      <div class="card-text">
        <p class="fw-bold">{text}</p>
        <img
          class="lazy-loading-src"
          src="/theme/malloftripla2024/images/point-to-right-white.png"
          alt="point-to-right"
          width="50"
          height="28" />
      </div>
    </a>
  </div>
{/if}

<style lang="scss">
  .biorex-card {
    display: block;
    position: relative;
    width: 100%;
    color: inherit;
    height: 100%;
    padding: 0 8px;
  }

  .biorex-card-image-wrapper {
    position: relative;
    display: block;
    width: 100%;
    overflow: hidden;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  .card-text {
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 1rem;

    p {
      font-size: 18px;
      line-height: 1.2;
      margin: 0;
      font-weight: 600;
      max-width: 80%;
    }

    img {
      width: 50px;
      margin-top: 1rem;
      height: auto;
    }
  }
</style> 