<!-- @format -->
<script lang="ts">
  import { onMount } from "svelte";
  import { readBrokenShows } from "./utils";
  import Title from "../common/Title.svelte";
  import Carousel, {
    type CarouselConfig,
  } from "../carousels/LiftCarousel.svelte";
  import BioRexCard from "./BioRexCard.svelte";
  import type { StoresKey } from "~/stores";
  import SeeAllBiorexCard from "./SeeAllBiorexCard.svelte";
  import { imageRatioValues } from "../../configuration";
  import type { Biorex } from "~/mall-of-tripla/biorex/type";
  import type { CarouselInfo } from "../carousels/utils";

  let class_list = "";
  export { class_list as class };
  export let request_store_key: StoresKey;
  export let biorexResponse: string;
  export let carouselInfo: CarouselInfo;
  
  let biorex: Biorex[];
  let config: CarouselConfig = { count: 0 };

  onMount(() => {
    const xml = new window.DOMParser().parseFromString(
      biorexResponse,
      "text/xml",
    );
    biorex = readBrokenShows(xml);
  });

  const {
    titleTextColor,
    textColor,
    textboxBackgroundColor,
    linkTextbox,
    linkTitle,
  } = carouselInfo;

  $: if (biorex && biorex.length) {
    config = {
      aspectRatio: imageRatioValues["BIOREX"],
      count: biorex.length + 1,
      initalSlideTo: 3,
      pos: 0,
    };
  }
</script>

{#if biorex && biorex.length}
  <div class="py-6 {class_list}">
      {#if linkTitle}
        <Title {linkTitle} {titleTextColor} />
      {/if}
      <Carousel {config}>
        <svelte:fragment slot="swiper">
          {#each biorex as item, index}
            <div class="swiper-slide">
              <BioRexCard
                aspectRatio={config.aspectRatio}
                biorex={item}
                {request_store_key} />
            </div>
            {#if index == config.pos && linkTextbox}
              <div class="swiper-slide">
                <SeeAllBiorexCard
                  {textColor}
                  backgroundColor={textboxBackgroundColor}
                  {linkTextbox}
                  aspectRatio={config.aspectRatio} />
              </div>
            {/if}
          {/each}
        </svelte:fragment>
      </Carousel>
  </div>
{/if}
